import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Notification from './Notification'; // นำเข้าคอมโพเนนต์การแจ้งเตือน
import './Checklottery.css';

const Checklottery = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const port = process.env.REACT_APP_PORT;
  const [sume, setSums] = useState(0);
  const [sume2, setSumstwo] = useState(0);
  const [sume3, setSumsthree] = useState(0);
  const [checkCount, setcheckCount] = useState(0);
  const [nonEmptyCount, setnonEmptyCount] = useState(0);
  const navigate = useNavigate();
  const [lotteryData, setLotteryData] = useState([]);
  const [roundTwoData, setRoundTwoData] = useState([]);
  const [roundThreeData, setRoundThreeData] = useState([]);
  const [uncheckedLotto, setUncheckedLotto] = useState(0);
  const [checkedLotto, setCheckedLotto] = useState(0);
  const [uncheckedRoundTwo, setUncheckedRoundTwo] = useState(0);
  const [checkedRoundTwo, setCheckedRoundTwo] = useState(0);
  const [uncheckedRoundThree, setUncheckedRoundThree] = useState(0);
  const [checkedRoundThree, setCheckedRoundThree] = useState(0);
  // สถานะเก็บข้อมูล Round_one ของแต่ละรายการ
  const [roundOneList, setRoundOneList] = useState([]);
  const [roundtwoList, setRoundtwoList] = useState([]);

  const [totalRoundOne, setTotalRoundOne] = useState(0);
  const [totalRoundTwo, setTotalRoundTwo] = useState(0);

  const loggedInUser = localStorage.getItem('username'); // ดึงค่าจาก localStorage

  // สถานะสำหรับการแจ้งเตือน
  const [notification, setNotification] = useState({
    message: '',
    show: false,
  });

  const fetchLotteryData = async () => {
    try {
      const response1 = await axios.get(`${baseUrl}:${port}/api/lotto-numbers`);
      setLotteryData(response1.data);
      // console.log(response1.data)

      const sume = response1.data.count
      setSums(sume)


      const response2 = await axios.get(`${baseUrl}:${port}/api/lotto-numbers-roundtwo`)
      setRoundTwoData(response2.data)
      // console.log(response2.data)

      const sume2 = response2.data.count
      setSumstwo(sume2)
      // console.log(sume2)

      const response3 = await axios.get(`${baseUrl}:${port}/api/lotto-numbers-roundthree`)
      setRoundThreeData(response3.data)
      // console.log(response3.data)

      const sume3 = response3.data.count
      setSumsthree(sume3)


    } catch (error) {
      console.error('Error fetching lottery data:', error);
    }
  };

  useEffect(() => {
    // เรียกใช้ฟังก์ชัน fetchLotteryData ทันทีเมื่อโหลดคอมโพเนนต์
    fetchLotteryData();

    // ตั้งเวลาทำการดึงข้อมูลทุกๆ 3 นาที (180,000 มิลลิวินาที)
    const intervalId = setInterval(() => {
      console.log("Interval triggered..."); // Log ทุกครั้งที่ setInterval ทำงาน
      fetchLotteryData();
    }, 60000);

    // ทำการล้าง interval เมื่อคอมโพเนนต์ถูกลบ
    return () => clearInterval(intervalId);
  }, []);

  const handleCheckLottery = (round) => {
    // ตรวจสอบว่าล็อตเตอรี่ทั้งหมดทั้ง 3 รอบเท่ากับ 0 หรือไม่
    // if (
    //   lotteryData.length === 0 &&
    //   roundTwoData.length === 0 &&
    //   roundThreeData.length === 0
    // ) {
    //   setNotification({ message: 'ยังไม่มีล็อตเตอรี่', show: true });
    //   return;
    // }
    if (round === 1) {
      // ตรวจสอบสำหรับรอบที่ 1
      // if (lotteryData.length > 0 && checkedLotto === lotteryData.length) {
      //   setNotification({ message: 'ล็อตเตอรี่หมด', show: true });
      //   return;
      // }
      navigate('/Round_one', { state: { round } });

    } else if (round === 2) {

      // ตรวจสอบว่า user ที่ล็อกอินมีอยู่ใน `roundOneList` หรือไม่
      const userExistsInRoundOne = roundOneList.some(item => item === loggedInUser);

      // if (!userExistsInRoundOne) {
      //   setNotification({ message: 'คุณไม่ได้เข้าร่วมรอบที่ 1', show: true });
      //   return;
      // }
      // ตรวจสอบสำหรับรอบที่ 2
      // if (totalRoundOne > 0 && checkedRoundTwo === totalRoundOne) {
      //   setNotification({ message: 'ล็อตเตอรี่หมด', show: true });
      //   return;
      // }
      // if (checkedLotto !== lotteryData.length) {
      //   setNotification({ message: 'กรุณาตรวจรอบที่ 1 ให้ครบ', show: true });
      //   return;
      // }
      navigate('/Round_two', { state: { round } });

    } else if (round === 3) {

      const userExistsInRoundOne = roundOneList.some(item => item === loggedInUser);
      const userExistsInRoundtwo = roundtwoList.some(item => item === loggedInUser);

      // if (!userExistsInRoundOne) {
      //   setNotification({ message: 'คุณไม่ได้เข้าร่วมรอบที่ 1', show: true });
      //   return;
      // }else if(!userExistsInRoundtwo){
      //   setNotification({ message: 'คุณไม่ได้เข้าร่วมรอบที่ 2', show: true });
      //   return;
      // }
      // ตรวจสอบสำหรับรอบที่ 3
      if (totalRoundTwo > 0 && checkedRoundThree === totalRoundTwo) {
        setNotification({ message: 'ล็อตเตอรี่หมด', show: true });
        return;
      }
      // if (totalRoundOne === 0) {
      //   setNotification({ message: 'ยังไม่มีล็อตเตอรี่ในรอบที่ 2', show: true });
      //   return;
      // }
      // if (checkedRoundTwo !== totalRoundOne) {
      //   setNotification({ message: 'กรุณาตรวจรอบที่ 2 ให้ครบ', show: true });
      //   return;
      // }
      navigate('/Round_three', { state: { round } });
    } else {
      console.log('Invalid round');
    }
  };


  const handleCloseNotification = () => {
    setNotification({ ...notification, show: false });
  };

  return (
    <div className="lottery-container">
      <h1 className="lottery-title">ตรวจลอตเตอรี่</h1>
      <div className="lottery-grid">
        {/* รอบที่ 1 */}
        <div className="lottery-section">
          <h2 className="lottery-subtitle">รอบที่ 1</h2>
          <div className="lottery-round">
            <div className="lottery-info">ลอตเตอรี่ทั้งหมด <span>{sume}</span> ใบ</div>
            {/* <div className="lottery-info">ยังไม่ได้ตรวจ <span>{emptyCount}</span> ใบ</div> */}
            {/* <div className="lottery-info">ตรวจแล้ว <span>{checkCount}</span> ใบ</div> */}
            <button className="lottery-btn" onClick={() => handleCheckLottery(1)}>ขอตรวจ</button>
          </div>
        </div>

        {/* รอบที่ 2 */}
        <div className="lottery-section">
          <h2 className="lottery-subtitle">รอบที่ 2</h2>
          <div className="lottery-round">
            <div className="lottery-info">ลอตเตอรี่ทั้งหมด <span>{sume2}</span> ใบ</div>
            {/* <div className="lottery-info">ยังไม่ได้ตรวจ <span>{uncheckedRoundTwo}</span> ใบ</div> */}
            {/* <div className="lottery-info">ตรวจแล้ว <span>{checkCount}</span> ใบ</div> */}
            <button className="lottery-btn" onClick={() => handleCheckLottery(2)}>ขอตรวจ</button>
          </div>
        </div>

        {/* รอบที่ 3 */}
        <div className="lottery-section">
          <h2 className="lottery-subtitle">รอบที่ 3</h2>
          <div className="lottery-round">
            <div className="lottery-info">ลอตเตอรี่ทั้งหมด <span>{sume3}</span> ใบ</div>
            {/* <div className="lottery-info">ยังไม่ได้ตรวจ <span>{uncheckedRoundThree}</span> ใบ</div>
            <div className="lottery-info">ตรวจแล้ว <span>{checkedRoundThree}</span> ใบ</div> */}
            <button className="lottery-btn" onClick={() => handleCheckLottery(3)}>ขอตรวจ</button>
          </div>
        </div>
      </div>

      {/* แสดงการแจ้งเตือน */}
      {notification.show && (
        <Notification message={notification.message} onClose={handleCloseNotification} />
      )}
    </div>
  );
};

export default Checklottery;
