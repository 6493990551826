
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Reporttable.css';

const Details = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const port = process.env.REACT_APP_PORT;
    const navigate = useNavigate();
    const [fail1Results, setFail1Results] = useState([]);
    const [fail0Results, setFail0Results] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null); // เก็บงวดที่เลือก

    // ดึงข้อมูลจาก API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}:${port}/api/report/details`);
                const fail1Data = response.data.fail1Results;

                setFail1Results(fail1Data);
                setFail0Results(response.data.fail0Results);

                // ตั้งค่างวดล่าสุดเป็นค่าเริ่มต้น
                if (fail1Data.length > 0) {
                    const latestPeriod = fail1Data[0].Period_no; // งวดล่าสุด
                    setSelectedPeriod(latestPeriod);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [baseUrl, port]);

    const handleCheckErrorClick = () => {
        navigate('/Wrongcheck');
    };

    const handlePeriodChange = (period) => {
        setSelectedPeriod(period); // อัปเดตงวดที่เลือก
    };

    // ฟังก์ชันเพื่อค้นหา `lottoNumbers` จาก `fail0Results` ตาม `id_ref`
    const getCorrectLottoNumbers = (id_ref) => {
        const result = fail0Results.find(row => row.id_ref === id_ref);
        return result ? result.lottoNumbers : ''; // ถ้าไม่พบให้คืนค่าเป็นค่าว่าง
    };

    // ฟังก์ชันจัดรูปแบบตัวเลขในรูปแบบ xx-xxxxxx-xx-xx
    const formatLottoNumbers = (numbers) => {
        const cleanNumbers = numbers.replace(/[^0-9]/g, ''); // เอาเฉพาะตัวเลข
        const firstPart = cleanNumbers.slice(0, 2); // 2 ตัวแรก
        const secondPart = cleanNumbers.slice(2, 8); // ตัวที่ 3 ถึง 8
        const thirdPart = cleanNumbers.slice(8, 10); // ตัวที่ 9 ถึง 10
        const fourthPart = cleanNumbers.slice(10, 12); // ตัวที่ 11 ถึง 12
        const extraPart = cleanNumbers.slice(12); // หมายเลขที่เกิน 12 ตัว (ถ้ามี)

        // หากมี extraPart (ตัวเลขที่เกิน 12 หลัก) ให้เพิ่มเข้าไปในผลลัพธ์
        if (extraPart) {
            return `${firstPart}-${secondPart}-${thirdPart}-${fourthPart}-${extraPart}`;
        }

        return `${firstPart}-${secondPart}-${thirdPart}-${fourthPart}`;
    };


    // ฟังก์ชันสำหรับการแยกและเปรียบเทียบตัวเลขแต่ละตัว
    const compareLottoNumbers = (correct, error) => {
        const maxLength = Math.max(correct.length, error.length); // หาความยาวที่มากที่สุดของทั้งสองค่า

        const correctNumbers = correct.split(''); // แยกตัวเลขจาก Correct
        const errorNumbers = error.split(''); // แยกตัวเลขจาก Error

        // ถ้าความยาวไม่เท่ากัน เราจะให้เทียบเฉพาะตัวเลขที่มี
        const comparisonResult = [];

        for (let i = 0; i < maxLength; i++) {
            const correctDigit = correctNumbers[i] || ''; // หากไม่มีตัวเลขก็จะใช้ค่าว่าง
            const errorDigit = errorNumbers[i] || ''; // หากไม่มีตัวเลขก็จะใช้ค่าว่าง

            comparisonResult.push({
                correct: correctDigit,
                error: errorDigit,
                isError: correctDigit !== errorDigit, // เปรียบเทียบตัวเลขตรงๆ
            });
        }
        return comparisonResult;
    };




    return (
        <div className="profile-container">
            <div className="profile-card">
                <div className="check-info">
                    <h1>รายละเอียด</h1>
                    <div className="buttongroup">
                        <button className="check-error-button" onClick={handleCheckErrorClick}>
                            ตรวจผิด
                        </button>
                        <div className="dropdown">
                            <button className="dropdown-button">
                                {selectedPeriod || 'เลือกงวด'} {/* แสดง selectedPeriod หรือข้อความ default */}
                            </button>
                            <div className="dropdown-content">
                                {[...new Set(fail1Results.map(row => row.Period_no))] // กำจัดค่าซ้ำ
                                    .sort((a, b) => new Date(b) - new Date(a)) // เรียงจากใหม่ไปเก่า โดยแปลงเป็น Date object
                                    .map((period, index) => (
                                        <a key={index} onClick={() => handlePeriodChange(period)}>
                                            {period}
                                        </a>
                                    ))}
                            </div>
                        </div>


                    </div>
                    <div className="styled-htable">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>งวดที่</th>
                                    <th>OrderID</th>
                                    <th>Correct</th>
                                    <th>Error</th>
                                    <th>User</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fail1Results
                                    .filter(row => row.Period_no === selectedPeriod) // กรองข้อมูลตามงวดที่เลือก
                                    .map((row, index) => {
                                        const correctLottoNumbers = getCorrectLottoNumbers(row.id_ref);
                                        const formattedCorrectLotto = formatLottoNumbers(correctLottoNumbers); // จัดรูปแบบตัวเลขใน Correct
                                        const formattedErrorLotto = formatLottoNumbers(row.lottoNumbers); // จัดรูปแบบตัวเลขใน Error

                                        console.log("formattedErrorLotto:", formattedErrorLotto); // ตรวจสอบค่าที่ได้หลังการจัดรูปแบบ
                                        const comparisonResult = compareLottoNumbers(formattedCorrectLotto, formattedErrorLotto);

                                        return (
                                            <tr key={index}>
                                                <td>{row.Period_no}</td>
                                                <td>{row.id_ref}</td>
                                                <td>
                                                    {comparisonResult.map((item, idx) => (
                                                        <span key={idx} className="correct-or">
                                                            {item.correct}
                                                        </span>
                                                    ))}
                                                </td>
                                                <td>
                                                    {comparisonResult.map((item, idx) => (
                                                        <span key={idx} className={item.isError ? 'error-or' : ''}>
                                                            {item.error}
                                                        </span>
                                                    ))}
                                                </td>
                                                <td>{row.username}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Details;
