import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './OnestPrize.css'; // ปรับตามชื่อไฟล์ CSS ของคุณ
import Notification from '../Checklotto/Notification';
import * as XLSX from 'xlsx';

const OnestPrize = () => {
  const [positionStatistics, setPositionStatistics] = useState([]);
  const [history, sethistory] = useState([]);
  const [newLottoNumbers, setNewLottoNumbers] = useState([]); // สถานะสำหรับเลขใหม่
  const [currentPage, setCurrentPage] = useState(1); // สถานะสำหรับหน้า
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState({}); // สถานะสำหรับเลขที่ถูกเลือก
  const [displayedNumbers, setDisplayedNumbers] = useState([]); // สำหรับแสดงเลขที่สร้าง
  const [loadingGenerate, setLoadingGenerate] = useState(false); // เพิ่ม loading สำหรับการสร้างเลข
  const [generatedCount, setGeneratedCount] = useState(0); // สถานะสำหรับเก็บจำนวนเลขที่สร้าง
  const [isBuilding, setIsBuilding] = useState(true); // สถานะของปุ่ม
  const [selectedDate, setSelectedDate] = useState(null); // สถานะสำหรับเก็บวันที่ที่เลือก
  const [selectedDateChange, setSelectedDateChange] = useState(null); // สถานะสำหรับเก็บวันที่ที่เลือก
  const [statusMessage, setStatusMessage] = useState('');
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(0); // ค่าเริ่มต้นเป็น 10
  const [newPairedNumbers, setNewPairedNumbers] = useState([]);
  const [matchedCount, setmatchedCount] = useState([]);
  const [unmatchedCount, setunmatchedCount] = useState([]);
  // const [selectedDateChange, setSelectedDateChange] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [lottoCheckCount, setLottoCheckCount] = useState(0);
  const [totalLogsCount, settotalLogsCount] = useState(0);
  const [nullLogsCount, setnullLogsCount] = useState(0);

  const [status, setStatus] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);
  const [devices, setDevices] = useState([]);  // สร้าง state สำหรับเก็บข้อมูลอุปกรณ์
  // const [currentPages, setCurrentPages] = useState({});
  const [isSingle, setIsSingle] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);




  const baseUrl = process.env.REACT_APP_BASE_URL;
  const port = process.env.REACT_APP_PORT;

  const itemsPerPage = 30; // จำนวน item ที่แสดงต่อหน้า

  useEffect(() => {
    // เชื่อมต่อกับ WebSocket server
    const socket = new WebSocket('ws://183.90.168.20:9891');

    // เมื่อเชื่อมต่อ WebSocket แล้ว
    socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    // เมื่อได้รับข้อมูลจาก WebSocket
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log('Received data from server:', data);

      // เช็คว่าข้อมูลที่ได้รับเป็นอาร์เรย์หรือไม่
      if (Array.isArray(data)) {
        setDevices(data);  // เก็บข้อมูลทั้งหมดลงใน state
        setLoadingGenerate(false);
      }
    };

    // ปิดการเชื่อมต่อ WebSocket เมื่อ component ถูกทำลาย
    return () => socket.close();
  }, []);




  // const fetchStatus = async () => {
  //   try {
  //     const response = await axios.get(`${baseUrl}:${port}/api/test/botstatus`);
  //     setStatusMessage(response.data.message); // เก็บข้อมูลที่ได้รับในตัวแปรสถานะ
  //     console.log(response.data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error); // แสดงข้อผิดพลาด
  //   }
  // };

  // // เรียก fetchStatus หนึ่งครั้งเมื่อ component โหลด
  // useEffect(() => {
  //   fetchStatus();
  // }, []);

  // ใช้ useEffect เพื่อตรวจสอบการเปลี่ยนแปลงของ statusMessage
  useEffect(() => {
    if (statusMessage) {
      console.log("Status message has changed:", statusMessage);
    }
  }, [statusMessage]); // รันทุกครั้งที่ statusMessage เปลี่ยนแปลง




  // useEffect(() => {
  //   // กำหนดวันที่ดีฟอลต์
  //   const today = new Date();
  //   const currentDay = today.getDate();

  //   let defaultDate;
  //   if (currentDay > 1 && currentDay <= 16) {
  //     // ถ้าวันนี้มากกว่า 1 แต่ไม่เกิน 15 ให้แสดงวันที่ 16
  //     defaultDate = new Date(today.getFullYear(), today.getMonth(), 16);
  //   } else if (currentDay > 16) {
  //     // ถ้าวันนี้มากกว่า 16 ให้แสดงวันที่ 1 ของเดือนถัดไป
  //     defaultDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  //   } else {
  //     // ถ้าวันนี้คือวันที่ 1 หรือก่อนหน้านั้นให้แสดงวันที่ 1 ของเดือนนี้
  //     defaultDate = new Date(today.getFullYear(), today.getMonth(), 1);
  //   }

  //   setSelectedDate(defaultDate);
  // }, []);


  useEffect(() => {
    const fetchPositionStatistics = async () => {
      try {
        const response = await axios.get(`${baseUrl}:${port}/api/lottosixnumber`);
        setPositionStatistics(response.data.positionStatistics);
        // setNewLottoNumbers(response.data.newLottoNumbers); // ดึง newLottoNumbers จาก API
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('เกิดข้อผิดพลาดในการดึงข้อมูล');
      } finally {
        setLoading(false);
      }
    };

    fetchPositionStatistics();
  }, []);




  // ฟังก์ชันการจัดการการเปลี่ยนแปลงวันที่
  const handleDateChange = async (date) => {
    setSelectedDateChange(date);

    // แปลงวันที่เป็นรูปแบบ 'YYYY-MM-DD'
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    console.log('Sending date to API:', formattedDate);

    // ส่งวันที่ไปที่ API
    try {
      const response = await axios.post(`${baseUrl}:${port}/api/order_buy`, {
        selectedDate: formattedDate, // ส่งวันที่ที่แปลงแล้ว
      });

      console.log('Response from API:', response.data);

      // ตรวจสอบและอัพเดตข้อมูลจาก API
      if (response.data && response.data.historyData) {
        setHistoryData(response.data.historyData);
      } else {
        setHistoryData([]);  // ถ้าไม่มีข้อมูล historyData ให้เซ็ตให้เป็นอาร์เรย์ว่าง
      }

      if (response.data && response.data.lottoCheckCount !== undefined) {
        setLottoCheckCount(response.data.lottoCheckCount);
      } else {
        setLottoCheckCount(0);  // ถ้าไม่มีข้อมูล lottoCheckCount ให้เซ็ตเป็น 0
      }
      if (response.data && response.data.totalLogsCount !== undefined) {
        settotalLogsCount(response.data.totalLogsCount);
      } else {
        settotalLogsCount(0);  // ถ้าไม่มีข้อมูล lottoCheckCount ให้เซ็ตเป็น 0
      }
      if (response.data && response.data.nullLogsCount !== undefined) {
        setnullLogsCount(response.data.nullLogsCount);
      } else {
        setnullLogsCount(0);  // ถ้าไม่มีข้อมูล lottoCheckCount ให้เซ็ตเป็น 0
      }

    } catch (error) {
      console.error('Error:', error);
      setHistoryData([]);  // ถ้าเกิดข้อผิดพลาดจาก API ก็ให้เซ็ต historyData เป็นอาร์เรย์ว่าง
      setLottoCheckCount(0); // ให้เซ็ต lottoCheckCount เป็น 0
      settotalLogsCount(0); // ให้เซ็ต lottoCheckCount เป็น 0
      setnullLogsCount(0); // ให้เซ็ต lottoCheckCount เป็น 0
    }
  };




  // const fetchhistory = async () => {
  //   try {
  //     const response = await axios.get(`${baseUrl}:${port}/api/test/historynumber`);
  //     sethistory(response.data.orderNumbers);

  //     console.log("History", response.data.orderNumbers)
  //     // setNewLottoNumbers(response.data.newLottoNumbers); // ดึง newLottoNumbers จาก API
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setError('เกิดข้อผิดพลาดในการดึงข้อมูล');
  //   } finally {
  //     setLoading(false);
  //   }
  // };



  // คำนวณเลขเริ่มต้นและเลขสิ้นสุดสำหรับหน้าปัจจุบัน
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNumbers = newLottoNumbers.slice(indexOfFirstItem, indexOfLastItem);


  // คำนวณ totalPages ตามจำนวน devices
  // const totalPages = Math.ceil(devices.length / itemsPerPage);


  const handleCheckboxChange = (digit, position, count) => {
    setSelected((prev) => {
      const updatedSelection = {
        ...prev,
        [position]: {
          ...prev[position],
          [digit]: !prev[position]?.[digit], // สลับสถานะของ checkbox
        },
      };

      // สร้างออบเจ็กต์ selectedNumbers โดยเลือกตัวเลขที่ผู้ใช้เลือกในแต่ละตำแหน่ง
      const selectedNumbers = {};
      for (let pos = 1; pos <= 6; pos++) {
        selectedNumbers[pos] = Object.keys(updatedSelection[pos] || {})
          .filter((key) => updatedSelection[pos][key]) // เลือกเฉพาะตัวเลขที่ผู้ใช้เลือก
          .map((key) => key);
      }

      // เรียงตัวเลขตามจำนวน count จากมากไปหาน้อย
      const sortedNumbers = {};
      for (const pos in selectedNumbers) {
        sortedNumbers[pos] = selectedNumbers[pos]
          .map((digit) => {
            const countForDigit = positionStatistics.find(
              (data) => data.position === parseInt(pos)
            )?.digits.find((digitData) => digitData.digit === digit)?.count;
            return { digit, count: countForDigit };
          })
          .sort((a, b) => b.count - a.count); // เรียงจากมากไปหาน้อย
      }

      // เริ่มจับคู่เลขจากตำแหน่งที่เลือก
      const selectedPositions = Object.keys(sortedNumbers).filter(
        (pos) => sortedNumbers[pos]?.length > 0
      );

      const newPairedNumbers = [];

      const combinations = (current, depth) => {
        if (depth === selectedPositions.length) {
          newPairedNumbers.push(current.join(''));
          return;
        }

        const pos = selectedPositions[depth];
        const digits = sortedNumbers[pos].map((item) => item.digit); // เลขที่เลือกในตำแหน่งนี้

        for (const digit of digits) {
          combinations([...current, digit], depth + 1);
        }
      };

      if (selectedPositions.length > 0) {
        combinations([], 0);
      }

      // log ตัวเลขที่จับคู่ได้
      // console.log('เลขที่จับคู่ได้:', newPairedNumbers);

      // อัปเดตจำนวนเลขที่ถูกสร้าง
      setGeneratedCount(newPairedNumbers.length);
      setSelectedQuantity(newPairedNumbers.length);
      setNewPairedNumbers(newPairedNumbers);

      return updatedSelection;
    });
  };


  const handleExportExcel = () => {
    const maxRows = 1000; // กำหนดจำนวนแถวสูงสุดใน sheet
    const totalRows = displayedNumbers.length; // จำนวนแถวทั้งหมด

    // สร้างข้อมูลสำหรับ worksheet
    const worksheetData = displayedNumbers.map((number, index) => ({
      'ลำดับ': index + 1, // ลำดับของตัวเลข
      'Newnumber': number, // ตัวเลขที่ไม่ซ้ำ
    }));

    // สร้าง Worksheet จากข้อมูล
    const ws = XLSX.utils.json_to_sheet(worksheetData);

    // กำหนดชื่อ sheet
    const sheetName = 'Sheet1';

    // สร้าง Workbook และเพิ่ม Worksheet เข้าไป
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // สร้างไฟล์ Excel และดาวน์โหลด
    XLSX.writeFile(wb, 'Newnumber.xlsx');
  };




  const handleGenerateNumbers = () => {

    if (!selectedDate) {
      setNotificationMessage('กรุณาเลือกวันที่ก่อนส่งข้อมูล');
      setIsNotificationOpen(true);
      return; // หยุดการทำงานของฟังก์ชัน
    }

    setCurrentPage(1); // รีเซ็ตหน้าไปที่หน้าแรก
    // แปลงวันที่ที่เลือกเป็นรูปแบบ yyyy-MM-dd
    const date = new Date(selectedDate);

    // เพิ่มวันเข้าไป 1 วัน
    date.setDate(date.getDate() + 1);

    // แปลงวันที่เป็น ISO string ในรูปแบบ YYYY-MM-DD
    const dateFormatted = date.toISOString().split('T')[0];

    axios
      .post(`${baseUrl}:${port}/api/historynumber`, {
        numbers: newPairedNumbers,
        date: dateFormatted, // ส่งวันที่ในรูปแบบ yyyy-MM-dd
      })
      .then((response) => {
        console.log("Response data:", response.data); // ตรวจสอบข้อมูลที่ได้รับจาก API

        // ดึงค่าซ้ำและไม่ซ้ำออกมา
        const matchedCount = response.data.matchedCount || []; // ค่าที่ตรงกัน
        setmatchedCount(matchedCount)
        const unmatchedCount = response.data.unmatchedCount || []; // ค่าที่ไม่ตรงกัน
        setunmatchedCount(unmatchedCount)

        //  // แสดงผลลัพธ์ใน console
        //  console.log("Matched Numbers:", matchedCount); // หมายเลขที่ซ้ำ
        //  console.log("Unmatched Numbers:", unmatchedCount); // หมายเลขที่ไม่ซ้ำ

        // ตรวจสอบว่า response.data.receivedNumbers เป็นอาเรย์หรือไม่
        if (Array.isArray(response.data.unmatchedNumbers) && response.data.unmatchedNumbers.length > 0) {
          setDisplayedNumbers(response.data.unmatchedNumbers); // ถ้ามีข้อมูลใน unmatchedNumbers
        } else if (Array.isArray(response.data.uniqueNumbers) && response.data.uniqueNumbers.length > 0) {
          setDisplayedNumbers(response.data.uniqueNumbers); // ถ้ามีข้อมูลใน uniqueNumbers
        } else {
          console.error("receivedNumbers is not an array:", response.data.unmatchedNumbers);
        }
      })
      .catch((error) => {
        console.error("Error sending data:", error); // จัดการกับข้อผิดพลาด
      });
  };

  const handleDeviceCheckboxChange = (e, deviceId) => {
    if (e.target.checked) {
      // เพิ่มเครื่องที่เชื่อมต่อ
      setSelectedDevices((prevSelectedDevices) => [...prevSelectedDevices, deviceId]);
    } else {
      // ลบเครื่องที่หยุดเชื่อมต่อ
      setSelectedDevices((prevSelectedDevices) =>
        prevSelectedDevices.filter((id) => id !== deviceId)
      );
    }
  };






  // const handleGenerateNumbers = () => {
  //   setIsBuilding(false); // เปลี่ยนสถานะปุ่มเป็น Submit

  //   // เรียกใช้ตัวเลขที่สร้างจาก handleCheckboxChange
  //   setDisplayedNumbers([...new Set(newPairedNumbers)]); // อัปเดตตัวเลขที่สร้างใน state

  //   setCurrentPage(1); // รีเซ็ตหน้าไปที่หน้าแรก
  // };


  const handleSubmit = async () => {
    // ตรวจสอบสถานะว่าเป็น 'Bot disconnected'
    if (statusMessage === 'Bot disconnected') {
      // แสดงข้อความแจ้งเตือนเมื่อไม่ได้เชื่อมต่อ
      setNotificationMessage('ไม่สามารถส่งข้อมูลได้ เนื่องจากการเชื่อมต่อถูกตัด');
      setIsNotificationOpen(true);
      setIsBuilding(true);
      return; // หยุดการทำงานของฟังก์ชัน
    }

    // ตรวจสอบว่ามีการเลือกวันที่หรือไม่
    if (!selectedDate) {
      setNotificationMessage('กรุณาเลือกวันที่ก่อนส่งข้อมูล');
      setIsNotificationOpen(true);
      return; // หยุดการทำงานของฟังก์ชัน
    }

    try {
      // สร้างวันที่ใหม่จาก selectedDate
      const date = new Date(selectedDate);

      // เพิ่มวันเข้าไป 1 วัน
      date.setDate(date.getDate() + 1);

      // แปลงวันที่เป็น ISO string ในรูปแบบ YYYY-MM-DD
      const dateFormatted = date.toISOString().split('T')[0];

      // สร้าง array ที่มีจำนวนตามที่เลือก (ใช้ selectedQuantity)
      const numbersToSend = displayedNumbers.slice(0, selectedQuantity);

      let numbersWithDeviceInfo = [];

      // กรองเครื่องที่เลือกจาก selectedDevices
      const selectedDevicesList = devices.filter(device => selectedDevices.includes(device.id));

      if (isSet) {
        // ถ้าเลือก "ชุด", ทุกเครื่องที่เลือกจะได้รับเลขชุดเดียวกัน
        numbersWithDeviceInfo = selectedDevicesList.map((device) => ({
          id: device.id,
          serial_number: device.serial_number,
          numbers: numbersToSend, // เลขชุดเดียวกันสำหรับทุกเครื่อง
        }));
      } else {
        // ถ้าเลือก "เดี่ยว", แบ่งเลขตามเครื่องที่เลือก
        numbersWithDeviceInfo = selectedDevicesList.map((device, deviceIndex) => {
          const numbersForDevice = numbersToSend.filter((_, index) => index % selectedDevicesList.length === deviceIndex);
          return {
            id: device.id, // เพิ่ม device.id
            serial_number: device.serial_number,
            numbers: numbersForDevice,
          };
        });
      }

      console.log('Sending data:', { numbersWithDeviceInfo });

      const response = await fetch(`${baseUrl}:${port}/api/saveLottoNumbers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          numbersWithDeviceInfo, // ส่งข้อมูลที่รวมเลขและ serial_number ของอุปกรณ์
          date: dateFormatted, // ส่งวันที่ไปใน request
        }),
      });

      console.log('HTTP Status:', response.status); // แสดงสถานะ HTTP

      if (!response.ok) {
        const errorMessage = await response.text(); // อ่านข้อความผิดพลาดจากการตอบกลับ
        throw new Error(`Network response was not ok: ${errorMessage}`);
      }

      const data = await response.json();
      console.log('Response:', data);

      // อัปเดต displayedNumbers เพื่อลบเลขที่ส่งไปแล้วออก
      const remainingNumbers = displayedNumbers.slice(selectedQuantity); // เลือกเลขที่ยังไม่ถูกส่ง
      setDisplayedNumbers(remainingNumbers); // อัปเดต state ของ displayedNumbers

      // ตั้งค่าข้อความแจ้งเตือนเมื่อส่งข้อมูลสำเร็จ
      setNotificationMessage('ส่งข้อมูลสำเร็จ!');
      setIsNotificationOpen(true);
      setIsBuilding(false); // แสดงสถานะว่ากำลังประมวลผลเสร็จสิ้น
    } catch (error) {
      console.error('Error submitting data:', error);

      // ตั้งค่าข้อความแจ้งเตือนเมื่อมีข้อผิดพลาด
      setNotificationMessage(`ส่งข้อมูลไม่สำเร็จ!: ${error.message}`);
      setIsNotificationOpen(true);
    }
};



  // const handleSubmit = async () => {
  //   // ตรวจสอบสถานะว่าเป็น 'Bot disconnected'
  //   if (statusMessage === 'Bot disconnected') {
  //     // แสดงข้อความแจ้งเตือนเมื่อไม่ได้เชื่อมต่อ
  //     setNotificationMessage('ไม่สามารถส่งข้อมูลได้ เนื่องจากการเชื่อมต่อถูกตัด');
  //     setIsNotificationOpen(true);
  //     setIsBuilding(true);
  //     return; // หยุดการทำงานของฟังก์ชัน
  //   }

  //   // ตรวจสอบว่ามีการเลือกวันที่หรือไม่
  //   if (!selectedDate) {
  //     setNotificationMessage('กรุณาเลือกวันที่ก่อนส่งข้อมูล');
  //     setIsNotificationOpen(true);
  //     return; // หยุดการทำงานของฟังก์ชัน
  //   }

  //   try {
  //     // สร้างวันที่ใหม่จาก selectedDate
  //     const date = new Date(selectedDate);

  //     // เพิ่มวันเข้าไป 1 วัน
  //     date.setDate(date.getDate() + 1);

  //     // แปลงวันที่เป็น ISO string ในรูปแบบ YYYY-MM-DD
  //     const dateFormatted = date.toISOString().split('T')[0];

  //     // สร้าง array ที่มีจำนวนตามที่เลือก (ใช้ selectedQuantity)
  //     const numbersToSend = displayedNumbers.slice(0, selectedQuantity);
  //     console.log('Sending data:', { numbers: numbersToSend });


  //     const response = await fetch(`${baseUrl}:${port}/api/test/saveLottoNumbers`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         numbers: numbersToSend, // ส่งเฉพาะจำนวนที่เลือก
  //         date: dateFormatted, // ส่งวันที่ไปใน request

  //       }),
  //     });

  //     console.log('HTTP Status:', response.status); // แสดงสถานะ HTTP

  //     if (!response.ok) {
  //       const errorMessage = await response.text(); // อ่านข้อความผิดพลาดจากการตอบกลับ
  //       throw new Error(`Network response was not ok: ${errorMessage}`);
  //     }

  //     const data = await response.json();
  //     console.log('Response:', data);

  //     // อัปเดต displayedNumbers เพื่อลบเลขที่ส่งไปแล้วออก
  //     const remainingNumbers = displayedNumbers.slice(selectedQuantity); // เลือกเลขที่ยังไม่ถูกส่ง
  //     setDisplayedNumbers(remainingNumbers); // อัปเดต state ของ displayedNumbers

  //     // ตั้งค่าข้อความแจ้งเตือนเมื่อส่งข้อมูลสำเร็จ
  //     setNotificationMessage('ส่งข้อมูลสำเร็จ!');
  //     setIsNotificationOpen(true);
  //     setIsBuilding(false); // แสดงสถานะว่ากำลังประมวลผลเสร็จสิ้น
  //   } catch (error) {
  //     console.error('Error submitting data:', error);

  //     // ตั้งค่าข้อความแจ้งเตือนเมื่อมีข้อผิดพลาด
  //     setNotificationMessage(`ส่งข้อมูลไม่สำเร็จ!: ${error.message}`);
  //     setIsNotificationOpen(true);
  //   }
  // };





  // const handleSubmit = async () => {
  //   // ตรวจสอบสถานะว่าเป็น 'Bot disconnected'
  //   if (statusMessage === 'Bot disconnected') {
  //     // แสดงข้อความแจ้งเตือนเมื่อไม่ได้เชื่อมต่อ
  //     setNotificationMessage('ไม่สามารถส่งข้อมูลได้ เนื่องจากการเชื่อมต่อถูกตัด');
  //     setIsNotificationOpen(true);
  //     setIsBuilding(true);
  //     return; // หยุดการทำงานของฟังก์ชัน
  //   }

  //   try {
  //     // สร้างวันที่ใหม่จาก selectedDate
  //     const date = new Date(selectedDate);

  //     // เพิ่มวันเข้าไป 1 วัน
  //     date.setDate(date.getDate() + 1);

  //     // แปลงวันที่เป็น ISO string ในรูปแบบ YYYY-MM-DD
  //     const dateFormatted = date.toISOString().split('T')[0];

  //     // สร้าง array ที่มีจำนวนตามที่เลือก (ใช้ selectedQuantity)
  //     const numbersToSend = displayedNumbers.slice(0, selectedQuantity);

  //     console.log('Sending data:', { numbers: numbersToSend });
  //     const response = await fetch(`${baseUrl}:${port}/api/test/saveLottoNumbers`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         numbers: numbersToSend, // ส่งเฉพาะจำนวนที่เลือก
  //         date: dateFormatted, // ส่งวันที่ไปใน request
  //       }),
  //     });

  //     console.log('HTTP Status:', response.status); // แสดงสถานะ HTTP

  //     if (!response.ok) {
  //       const errorMessage = await response.text(); // อ่านข้อความผิดพลาดจากการตอบกลับ
  //       throw new Error(`Network response was not ok: ${errorMessage}`);
  //     }

  //     const data = await response.json();
  //     console.log('Response:', data);

  //     // อัปเดต displayedNumbers เพื่อลบเลขที่ส่งไปแล้วออก
  //     const remainingNumbers = displayedNumbers.slice(selectedQuantity); // เลือกเลขที่ยังไม่ถูกส่ง
  //     setDisplayedNumbers(remainingNumbers); // อัปเดต state ของ displayedNumbers

  //     // ตั้งค่าข้อความแจ้งเตือนเมื่อส่งข้อมูลสำเร็จ
  //     setNotificationMessage('ส่งข้อมูลสำเร็จ!');
  //     setIsNotificationOpen(true);
  //     setIsBuilding(false); // แสดงสถานะว่ากำลังประมวลผลเสร็จสิ้น
  //   } catch (error) {
  //     console.error('Error submitting data:', error);

  //     // ตั้งค่าข้อความแจ้งเตือนเมื่อมีข้อผิดพลาด
  //     setNotificationMessage(`ส่งข้อมูลไม่สำเร็จ!: ${error.message}`);
  //     setIsNotificationOpen(true);
  //   }
  // };


  // const handleSubmit = async () => {
  //   // ตรวจสอบสถานะว่าเป็น 'Bot disconnected'
  //   if (statusMessage === 'Bot disconnected') {
  //     // แสดงข้อความแจ้งเตือนเมื่อไม่ได้เชื่อมต่อ
  //     setNotificationMessage('ไม่สามารถส่งข้อมูลได้ เนื่องจากการเชื่อมต่อถูกตัด');
  //     setIsNotificationOpen(true);
  //     setIsBuilding(true);
  //     return; // หยุดการทำงานของฟังก์ชัน
  //   }

  //   try {
  //     // สร้างวันที่ใหม่จาก selectedDate
  //     const date = new Date(selectedDate);

  //     // เพิ่มวันเข้าไป 1 วัน
  //     date.setDate(date.getDate() + 1);

  //     // แปลงวันที่เป็น ISO string ในรูปแบบ YYYY-MM-DD
  //     const dateFormatted = date.toISOString().split('T')[0];

  //     // สร้าง array ที่มีจำนวนตามที่เลือก (ใช้ selectedQuantity)
  //     const numbersToSend = displayedNumbers.slice(0, selectedQuantity);

  //     console.log('Sending data:', { numbers: numbersToSend });
  //     const response = await fetch(`${baseUrl}:${port}/api/test/saveLottoNumbers`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         numbers: numbersToSend, // ส่งเฉพาะจำนวนที่เลือก
  //         date: dateFormatted, // ส่งวันที่ไปใน request
  //       }),
  //     });

  //     console.log('HTTP Status:', response.status); // แสดงสถานะ HTTP

  //     if (!response.ok) {
  //       const errorMessage = await response.text(); // อ่านข้อความผิดพลาดจากการตอบกลับ
  //       throw new Error(`Network response was not ok: ${errorMessage}`);
  //     }

  //     const data = await response.json();
  //     console.log('Response:', data);

  //     // ตั้งค่าข้อความแจ้งเตือนเมื่อส่งข้อมูลสำเร็จ
  //     setNotificationMessage('ส่งข้อมูลสำเร็จ!');
  //     setIsNotificationOpen(true);
  //     setIsBuilding(true); // แสดงสถานะกำลังประมวลผล
  //   } catch (error) {
  //     console.error('Error submitting data:', error);

  //     // ตั้งค่าข้อความแจ้งเตือนเมื่อมีข้อผิดพลาด
  //     setNotificationMessage(`ส่งข้อมูลไม่สำเร็จ!: ${error.message}`);
  //     setIsNotificationOpen(true);
  //   }
  // };



  const handleCloseNotification = () => {
    setIsNotificationOpen(false);
  };


  // ฟังก์ชันสำหรับเปลี่ยนหน้า
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };


  // คำนวณเลขที่จะแสดงในหน้าปัจจุบัน
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = displayedNumbers.slice(startIndex, endIndex);

  const totalPages = Math.ceil(displayedNumbers.length / itemsPerPage);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="scrollable-container">
      <h1>รางวัลที่ 1</h1>
      <div className="position-grid">
        {positionStatistics.map((positionData) => (
          <div key={positionData.position} className="position-item">
            <h3>ตำแหน่งที่ {positionData.position}</h3>
            <ul>
              {positionData.digits.map((digitData) => (
                <li key={digitData.digit}>
                  เลข: {digitData.digit} จำนวน: {digitData.count} ครั้ง
                  <input
                    type="checkbox"
                    className="digit-checkbox"
                    onChange={() => handleCheckboxChange(digitData.digit, positionData.position, digitData.count)} // ฟังก์ชันสำหรับจัดการการเปลี่ยนแปลงเช็กบล็อก
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>


      <div className='container-box'>

        <div className="box-container">
          <div className="quantity-labeltop">
            ซื้องวดที่ : {selectedDateChange ? selectedDateChange.toLocaleDateString('th-TH') : ''}
          </div>

          <div className="date-picker">
            <label>งวดวันที่: </label>
            <DatePicker
              selected={selectedDateChange}
              onChange={handleDateChange} // ให้ผู้ใช้เลือกวันที่
              dateFormat="dd/MM/yyyy"
              placeholderText="กรุณาเลือกงวดที่ออก" // ข้อความที่แสดงเมื่อยังไม่ได้เลือก
            />
          </div>

          {/* แสดงข้อมูล historyData */}
          <div className="history-data">
            {historyData && historyData.length > 0 ? (
              historyData.map((item) => (
                <div key={item.id} className="history-item">
                  <span>รอบที่ {item.id} : {item.sum_number} ใบ : ผ่าน {item.matchedCount} ใบ </span>
                </div>
              ))
            ) : (
              <p className="no-data-text">ไม่มีข้อมูล</p> // ถ้าไม่มีข้อมูลจะแสดงข้อความนี้
            )}
          </div>

          <div className="lotto-check-count">
            {totalLogsCount > 0 ? (
              <p className="count-text">จำนวนทั้งหมด {totalLogsCount} ใบ</p> // ถ้ามีข้อมูลจะโชว์จำนวน
            ) : (
              <p className="no-data-text">ยังไม่มีข้อมูลจำนวนทั้งหมด</p> // ถ้าไม่มีข้อมูลจะแสดงข้อความนี้
            )}
          </div>

          <div className="lotto-check-count">
            {nullLogsCount > 0 ? (
              <p className="count-text">ยังไม่ได้ตรวจ {nullLogsCount} ใบ</p> // ถ้ามีข้อมูลจะโชว์จำนวน
            ) : (
              <p className="no-data-text">ยังไม่มีข้อมูลไม่ได้ตรวจ</p> // ถ้าไม่มีข้อมูลจะแสดงข้อความนี้
            )}
          </div>

          <div className="lotto-check-count">
            {lottoCheckCount > 0 ? (
              <p className="count-text">ตรวจผ่าน {lottoCheckCount} ใบ</p> // ถ้ามีข้อมูลจะโชว์จำนวน
            ) : (
              <p className="no-data-text">ยังไม่มีข้อมูลการตรวจผ่าน</p> // ถ้าไม่มีข้อมูลจะแสดงข้อความนี้
            )}
          </div>

        </div>
        <div className="quantity-container">
          <div className="quantity-label">
            จำนวนเลข : {generatedCount} ใบ
          </div>

          <div className="date-pickerbt">
            <label>งวดวันที่:</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)} // ให้ผู้ใช้เลือกวันที่
              dateFormat="dd/MM/yyyy"
              placeholderText="กรุณาเลือกงวดที่ออก" // ข้อความที่แสดงเมื่อยังไม่ได้เลือก
            />
          </div>

          <div className="header">
            <span className="amount-label"><h4>จำนวนที่จะส่ง</h4></span>
            <input
              type="number"
              id="quantity"
              value={selectedQuantity} // ค่าที่กรอกจะถูกเก็บใน state selectedQuantity
              onChange={(e) => setSelectedQuantity(Number(e.target.value))} // เมื่อกรอกจะอัปเดต selectedQuantity
              min="1" // กำหนดจำนวนขั้นต่ำที่กรอกได้
              max="10000" // กำหนดจำนวนสูงสุดที่กรอกได้
              className="quantity-input"
              placeholder="กรอกจำนวน"
            />
            <span className="amount-label">เลขที่ซ้ำ: {matchedCount}</span>

            {/* แสดงจำนวนที่ไม่ซ้ำ */}
            <span className="amount-label">เลขที่ไม่ซ้ำ: {unmatchedCount}</span>
          </div>
          <div className="checkbox-options">
            <label>
              <input
                type="checkbox"
                checked={isSingle}
                onChange={(e) => setIsSingle(e.target.checked)} // อัปเดตสถานะ isSingle
              />
              เดี่ยว
            </label>
            <label>
              <input
                type="checkbox"
                checked={isSet}
                onChange={(e) => setIsSet(e.target.checked)} // อัปเดตสถานะ isSet
              />
              ชุด
            </label>
          </div>

          {/* ส่วนที่เลือกเครื่อง */}
          <div className="device-checkbox-options">
            {devices && devices.length > 0 ? (
              devices.map((device) => (
                <div key={device.id} className="device-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedDevices.includes(device.id)}
                      onChange={(e) => handleDeviceCheckboxChange(e, device.id)}
                    />
                    เครื่องที่ {device.id}
                  </label>
                </div>
              ))
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>ยังไม่มีเครื่องที่เชื่อมต่อ</div>
            )}

          </div>


          <div className="button-container">
            <button className="excel-button" onClick={handleExportExcel}>
              Excel
            </button>
            <button className="create-button" onClick={handleGenerateNumbers}>
              Create
            </button>
            <button className="submit-button" onClick={handleSubmit}>
              Send
            </button>
          </div>
        </div>
      </div>



      {isNotificationOpen && (
        <Notification message={notificationMessage} onClose={handleCloseNotification} />
      )}

<div>
  {loadingGenerate ? (
    <div className="loading-container">
      <div className="loading-spinner"></div>
      <div className="loading-text">กำลังโหลดข้อมูล...</div>
    </div>
  ) : (
    selectedDevices.length > 0 ? (
      selectedDevices.map((deviceId, deviceIndex) => {
        // หาข้อมูลของเครื่องจาก selectedDevices
        const device = devices.find((d) => d.id === deviceId);

        // หากไม่พบเครื่องที่ตรงกับ deviceId ให้แสดงข้อความว่าไม่พบข้อมูล
        if (!device) {
          return <div key={deviceIndex} className="no-device-found"></div>;
        }

        let numbersForDevice = [];

        if (isSingle) {
          // แบ่งเลขตามเครื่องที่เลือก
          numbersForDevice = currentItems.filter((_, index) =>
            index % selectedDevices.length === deviceIndex
          );
        } else if (isSet) {
          // ถ้าเลือก "ชุด ทุกเครื่องจะแสดงเลขชุดเดียวกัน"
          numbersForDevice = currentItems;
        }

        return (
          <div key={deviceIndex} className="CardonestPrize">
            {/* แสดงข้อมูลของเครื่อง */}
            <div className="device">
              <span>เครื่องที่: {device.id || 'ไม่พบข้อมูลเครื่อง'}</span>
              <span>Status: {device.status || 'ไม่มีข้อมูลสถานะ'}</span>
              <span>Serial: {device.serial_number || 'ไม่มีข้อมูลซีเรียล'}</span>
            </div>

            <h3>เลขที่ไม่ซ้ำ</h3>
            {numbersForDevice.length > 0 ? (
              <div className="new-numbers-grid">
                {numbersForDevice.map((number, index) => (
                  <div key={index} className="new-number-item">
                    {number}
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-numbers">ยังไม่มีเลขที่สร้าง</div>
            )}

            {/* ปุ่มสำหรับการเปลี่ยนหน้า */}
            <div className="pagination-controls">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                ก่อนหน้า
              </button>
              <span>หน้า {currentPage} จาก {totalPages}</span>
              <button onClick={handleNextPage} disabled={currentPage >= totalPages}>
                ถัดไป
              </button>
            </div>
          </div>
        );
      })
    ) : (
      <div style={{ color: 'red', fontWeight: 'bold' }} className="no-numbers">ไม่มีเครื่องที่เลือก</div>
    )
  )}
</div>


    </div>
  );
};

export default OnestPrize;

