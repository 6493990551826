import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './Roundone.css';

const Round_three = () => {
    const [displayUsername] = useState(localStorage.getItem('username'));
    const [lottoNumbers, setLottoNumbers] = useState({});
    const [imageUrl, setImageUrl] = useState('');
    const [period_no, setperiod_no] = useState('');
    const [imageimageFileName, setImageimageFileName] = useState('');
    const [remark, setRemark] = useState('');
    const [id, setid] = useState('');
    const [status, setStatus] = useState('1');
    const [loading, setLoading] = useState(false); // สถานะการโหลด
    const navigate = useNavigate();
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showBackConfirmPopup, setShowBackConfirmPopup] = useState(false);
    const [showNoDataPopup, setShowNoDataPopup] = useState(false);
    const [showsendingDataPopup, setShowsendingDataPopup] = useState(false);
    const location = useLocation();
    const { round } = location.state || {};
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const port = process.env.REACT_APP_PORT;
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);



    const formatLottoNumber = (number) => {
        return {
            part1: number.slice(0, 2),
            part2: number.slice(2, 8),
            part3: number.slice(8, 10),
            part4: number.slice(10),
        };
    };

    useEffect(() => {
        fetchLottoData();
    }, []); // ใช้ [] เพื่อให้เรียกแค่ครั้งเดียว

    const fetchLottoData = async () => {
        if (!displayUsername) return;

        setLoading(true); // เริ่มการโหลด
        try {
            const currentTime = new Date().toISOString().slice(0, 19).replace('T', ' '); // '2024-11-05 07:16:56'

            const response = await axios.post(
                `${baseUrl}:${port}/api/lotto-numbers/Round_three`,
                { username: displayUsername, time: currentTime },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (!data || !data.id) {
                // กรณีที่ไม่มีข้อมูล
                console.log("ล็อตเตอรี่หมด");
                setLottoNumbers({});
                setImageUrl('');
                setperiod_no('');
                setid('');
                setImageimageFileName('');
                return; // ออกจากฟังก์ชัน
            }

            console.log(data);
            setImageimageFileName(data.imageFileName);
            setperiod_no(data.Period_no);
            setid(data.id);

            if (typeof data.lottoNumbers === 'string') {
                const formattedNumber = formatLottoNumber(data.lottoNumbers);
                setLottoNumbers(formattedNumber);
            } else {
                setLottoNumbers({});
            }

            setImageUrl(data.imageUrl || '');
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // จัดการกรณี API ส่งรหัสสถานะ 404
                console.error("API returned 404: Data not found");
                setLottoNumbers({});
                setImageUrl('');
                setperiod_no('');
                setid('');
                setImageimageFileName('');
                setShowNoDataPopup(true); // แสดง popup แจ้งเตือน
            } else {
                console.error('Fetch error:', error);
            }
        } finally {
            setLoading(false); // เมื่อการโหลดเสร็จสิ้น
        }
    };



    const handleInputChange = (part, value) => {
        setLottoNumbers((prevLottoNumbers) => ({
            ...prevLottoNumbers,
            [part]: value,
        }));
    };

    const handleRemarkChange = (value) => {
        setRemark(value);
    };

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const openBackConfirmPopup = () => {
        setShowBackConfirmPopup(true);
    };

    const closeBackConfirmPopup = () => {
        setShowBackConfirmPopup(false);
    };

    const confirmBackAction = async () => {
        setShowBackConfirmPopup(false);
        navigate('/checklottery');
    };

    const navigateToCheckLottery = () => {
        setShowNoDataPopup(false); // ปิด popup
        navigate('/checklottery'); // ไปที่หน้า /checklottery
    };

    const sendingDataPopup = () => {
        setShowsendingDataPopup(false); // ปิด popup
        navigate('/checklottery'); // ไปที่หน้า /checklottery
    };

    const closeSuccessPopup = async () => {
        // if (isButtonDisabled) return; // ป้องกันไม่ให้กดซ้ำ

        // setIsButtonDisabled(true); // ปิดการกดปุ่ม
        setShowSuccessPopup(false); // ปิด Popup
        setStatus('1');

        await fetchLottoData(); // ดึงข้อมูลหลังจากปิด Popup
    };


    const handleButtonClick = async () => {
        if (isButtonDisabled) return; // ป้องกันไม่ให้กดซ้ำ

        setIsButtonDisabled(true); // ปิดการกดปุ่ม

        const fullLottoNumber = `${lottoNumbers.part1}${lottoNumbers.part2}${lottoNumbers.part3}${lottoNumbers.part4}`;

        try {
            const response = await axios.post(
                `${baseUrl}:${port}/api/update-lotto-data/Round_three`,
                {
                    id: id,
                    username: displayUsername,
                    imageFileName: imageimageFileName,
                    lottoNumber: fullLottoNumber,
                    period_no: period_no,
                    remark: remark,
                    status: status,
                    round: round
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log('API Response:', response.data);

            setShowSuccessPopup(true); // เปิด popup สำเร็จ
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // จัดการกรณี API ส่งรหัสสถานะ 404
                console.error("API returned 404: Data not found");
                setLottoNumbers({});
                setImageUrl('');
                setperiod_no('');
                setid('');
                setImageimageFileName('');
                setShowsendingDataPopup(true); // แสดง popup แจ้งเตือน
            } else {
                console.error('Fetch error:', error);
            }
        } finally {
            setIsButtonDisabled(false); // ปลดล็อกปุ่มไม่ว่าจะสำเร็จหรือเกิดข้อผิดพลาด
        }
    };

    return (
        <div className="product-container">
            <h2 className='lottery-title'>ตรวจล็อตเตอรี่รอบที่ {round}</h2>
            <div className="product-item-wrapper">
                <div className="product-item">
                    <div className="left-column">
                        {loading ? (
                            <div className="loading">กำลังโหลด...</div>
                        ) : (
                            <img
                                src={imageUrl}
                                alt={`Lotto ${lottoNumbers.part1}${lottoNumbers.part2}${lottoNumbers.part3}${lottoNumbers.part4}`}
                            />
                        )}
                        <div>ID {id}</div>
                        <div className="lotto-number-container">
                            <div className="lotto-numbers">
                                {/* <input
                                    className="lotto-input"
                                    type="text"
                                    value={lottoNumbers.part1 || ''}
                                    onChange={(e) => handleInputChange('part1', e.target.value)}
                                    maxLength={2}
                                /> */}
                                <input
                                    className="lotto-input"
                                    type="text"
                                    value="80"
                                    readOnly
                                />
                                <span className="separator">|</span>
                                <input
                                    className="lotto-input lotto-input-xxxxxx"
                                    type="text"
                                    value={lottoNumbers.part2 || ''}
                                    onChange={(e) => handleInputChange('part2', e.target.value)}
                                    maxLength={6}
                                />
                                <span className="separator">|</span>
                                <input
                                    className="lotto-input"
                                    type="text"
                                    value={lottoNumbers.part3 || ''}
                                    onChange={(e) => handleInputChange('part3', e.target.value)}
                                    maxLength={2}
                                />
                                <span className="separator">|</span>
                                <input
                                    className="lotto-input"
                                    type="text"
                                    value={lottoNumbers.part4 || ''}
                                    onChange={(e) => handleInputChange('part4', e.target.value)}
                                    maxLength={2}
                                />
                            </div>
                        </div>
                        <textarea
                            className="remark-input"
                            placeholder="ใส่หมายเหตุที่นี่..."
                            value={remark}
                            onChange={(e) => handleRemarkChange(e.target.value)}
                        />
                    </div>
                    <div className="right-column">
                        <div className="radio-container">
                            <label>
                                <input
                                    type="radio"
                                    name="status"
                                    value="1"
                                    checked={status === '1'}
                                    onChange={() => handleStatusChange('1')}
                                />
                                ผ่าน
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="status"
                                    value="0"
                                    checked={status === '0'}
                                    onChange={() => handleStatusChange('0')}
                                />
                                ไม่ผ่าน
                            </label>

                        </div>
                    </div>
                </div>
            </div>
            <div className="note-container-wrapper">
                <div className="note-container">
                    <button className="note-back-button" onClick={openBackConfirmPopup}>Back</button>
                    <button
                        className="note-button"
                        onClick={handleButtonClick}
                        disabled={isButtonDisabled} // ทำให้ปุ่มไม่สามารถกดได้หลังจากกดไปแล้ว
                    >
                        OK
                    </button>
                </div>
            </div>
            {showBackConfirmPopup && (
                <div className="popup-notification">
                    <div className="popup-content">
                        <div className="popup-message">
                            คุณแน่ใจหรือไม่ว่าต้องการออกจากหน้านี้!
                        </div>
                        <div className="popup-buttons">
                            <button className="confirm-button" onClick={confirmBackAction}>ตกลง</button>
                            <button className="cancel-button" onClick={closeBackConfirmPopup}>ยกเลิก</button>
                        </div>
                    </div>
                </div>
            )}
            {showSuccessPopup && (
                <div className="popup-notification">
                    <div className="popup-content">
                        <div className="popup-message">
                            ตรวจสำเร็จ!
                        </div>
                        <button
                            className="popup-button"
                            onClick={closeSuccessPopup}
                        // disabled={isButtonDisabled} // ทำให้ปุ่มไม่สามารถกดได้
                        >
                            ตกลง
                        </button>

                    </div>
                </div>
            )}

            {showNoDataPopup && (
                <div className="popup-notification">
                    <div className="popup-content">
                        <div className="popup-message">
                            ไม่พบข้อมูลล็อตเตอรี่ !
                        </div>
                        <button
                            className="popup-button"
                            onClick={navigateToCheckLottery} // เรียกฟังก์ชัน navigate
                        >
                            ตกลง
                        </button>
                    </div>
                </div>
            )}

            {showsendingDataPopup && (
                <div className="popup-notification">
                    <div className="popup-content">
                        <div className="popup-message">
                            ล็อตเตอรี่ถูกตรวจไปแล้ว !
                        </div>
                        <button
                            className="popup-button"
                            onClick={sendingDataPopup} // เรียกฟังก์ชัน navigate
                        >
                            ตกลง
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Round_three